<template>
  <app-form-view
    app="audit"
    model="tasktemplateheader"
    api-url="task/task-template-header/"
    :title="$t('menu.taskTemplate')"
    :state="formData.state"
    :stateItems="stateItems"
    :prepare-data="prepareData"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="master_task_template"
            type="select-server"
            :label="$t('fields.masterTaskTemplate')"
            :view="view"
            :binds="{
              apiUrl: 'task/master-task-template-header/?active=true'
            }"
            v-model="formData.master_task_template_header_id"
            @input="getDataFromMaster"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTemplateSubCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_task_template_header"
          />
        </v-col>

        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.templateName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="template_sub_category"
            type="select-server"
            rules="required"
            :label="$t('fields.templateSubCategory')"
            :view="view"
            :binds="{
              apiUrl: 'task/task-sub-category/?active=true'
            }"
            v-model="formData.task_sub_category_id"
            ref="testSubCategory"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTemplateSubCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_template_sub_category"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <app-input
            name="isPreAudit"
            type="switch"
            :label="$t('fields.preAudit')"
            :view="view"
            v-model="formData.is_pre_audit"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveisPreAudit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_is_pre_audit"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <app-input
            name="isAudit"
            type="switch"
            :label="$t('fields.audit')"
            :view="view"
            v-model="formData.is_audit"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveisAudit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_is_audit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="isPostAudit"
            type="switch"
            :label="$t('fields.postAudit')"
            :view="view"
            v-model="formData.is_post_audit"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveisPostAudit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_is_post_audit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-card>
        <v-tabs background-color="primary" dark v-model="tab">
          <v-tab>Description</v-tab>
          <v-tab>Required Document</v-tab>
          <v-tab>Property and Agreement</v-tab>
          <v-tab>Questionnaire</v-tab>
          <v-tab>Position(Role)</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Description Tab -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-input
                  name="Description"
                  type="html-editor"
                  :label="$t('fields.description')"
                  :view="view"
                  v-model="formData.description"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Required Doc Tab -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  app="audit"
                  model="tasktemplaterequireddocument"
                  :headers="requiredDocHeader"
                  :serverItems="requiredDocItems"
                  :clientItems="requiredDocItems"
                  :loading="loading"
                  :server-items-length="requiredDocServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getRequiredDocItems"
                  @delete="onDeleteRequiredDocItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Required Document</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRequiredDoc"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteRequiredDocItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedDocumentItem.document_template_id
                                        "
                                        name="document-template"
                                        type="select-server"
                                        :label="$t('fields.documentTemplate')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'doc-template/document/?active=true'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeRequiredDoc"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveRequiredDoc"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Propery and Agreement Tab -->
          <!-- Propery and Agreement Tab -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  :isSelecteable="false"
                  app="audit"
                  model="tasktemplatepropertyandagreement"
                  :headers="propertyAndAgreementHeader"
                  :serverItems="propertyAndAgreementItems"
                  :clientItems="propertyAndAgreementItems"
                  :loading="loading"
                  :server-items-length="propertyAndAgreementServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getPropertyAndAgreementItems"
                  @delete="onDeletePropertyAndAgreementItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >Property and Agreement</v-toolbar-title
                          >
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogPropertyAndAgreement"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="
                                    onDeletePropertyAndAgreementItem(selected)
                                  "
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedPropertyAndAgreementItem.property_and_agreement
                                        "
                                        name="porpery_and_agreement"
                                        type="select"
                                        :label="
                                          $t('fields.propertyAndAgreement')
                                        "
                                        :view="view"
                                        :items="[
                                          {
                                            label: $t('label.asset'),
                                            value: 'asset'
                                          },
                                          {
                                            label: $t('label.liability'),
                                            value: 'liability'
                                          },
                                          {
                                            label: $t('label.equity'),
                                            value: 'equity'
                                          },
                                          {
                                            label: $t('label.revenue'),
                                            value: 'revenue'
                                          },
                                          {
                                            label: $t('label.expense'),
                                            value: 'expense'
                                          },
                                          {
                                            label: $t('label.human_asset'),
                                            value: 'human_asset'
                                          }
                                        ]"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closePropertyAndAgreement"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="savePropertyAndAgreement"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Questionnaire Tab -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  :isSelecteable="false"
                  app="audit"
                  model="tasktemplatequestionnaireheader"
                  :headers="questionnaireHeader"
                  :serverItems="questionnaireHeaderItems"
                  :clientItems="questionnaireHeaderItems"
                  :loading="loading"
                  :server-items-length="questionnaireHeaderServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getQuestionnaireHeaderItems"
                  @delete="onDeleteQuestionnaireHeaderItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Questionnaire</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogQuestionnaireHeader"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="
                                    mode === 'edit' &&
                                    questionnaireHeaderServerItemsLength === 0
                                  "
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="
                                    onDeleteQuestionnaireHeaderItem(selected)
                                  "
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedQuestionnaireHeaderItem.questionnaire_header_id
                                        "
                                        name="questionnaire-select"
                                        type="select-server"
                                        :label="$t('fields.questionnaire')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'questionnaire/questionnaire-header/?active=true'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeQuestionnaireHeader"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveQuestionnaireHeader"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td :colspan="2">
                        <app-table
                          hide-edit
                          hide-delete
                          :isSelecteable="false"
                          app="audit"
                          model="tasktemplatequestionnaireheader"
                          :headers="questionnaireDetailHeader"
                          :serverItems="questionnaireDetailItems"
                          :clientItems="questionnaireDetailItems"
                          :loading="loading"
                          :server-items-length="
                            questionnaireDetailServerItemsLength
                          "
                          @view="onViewQuestionLine"
                        >
                          <template v-slot:top>
                            <div class="app-list-view">
                              <div class="app-list-view--header">
                                <v-toolbar flat>
                                  <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                  ></v-divider>
                                  <v-spacer></v-spacer>
                                  <v-dialog
                                    v-model="dialogQuestionnaireLine"
                                    max-width="1500px"
                                  >
                                    <v-card>
                                      <v-card-text class="pt-4">
                                        <v-toolbar-title
                                          >Questionnaire</v-toolbar-title
                                        >
                                        <v-divider
                                          class="mx-4"
                                          inset
                                          vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <app-table
                                          server-side
                                          app="audit"
                                          model="mastertasktemplatequestionnaireline"
                                          :headers="questionnaireLineHeader"
                                          :serverItems="questionnaireLineItems"
                                          :clientItems="questionnaireLineItems"
                                          :loading="loading"
                                          :server-items-length="
                                            questionnaireLineServerItemsLength
                                          "
                                          :isSelecteable="false"
                                          @server="getQuestionnaireLine"
                                        />
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="closeQuestionnaireLine"
                                        >
                                          Close
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-toolbar>
                              </div>
                            </div>
                          </template>
                        </app-table>
                      </td>
                    </tr>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Position Tab -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  :isSelecteable="false"
                  app="audit"
                  model="tasktemplateposition"
                  :headers="positionHeaders"
                  :serverItems="departmentItems"
                  :clientItems="departmentItems"
                  :loading="loading"
                  :server-items-length="departmentServerItemsLength"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getDepartmentItems"
                  @edit="onEditDepartmentItem"
                  @delete="onDeleteDepartmentItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Position (Role)</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogDepartment"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteDepartmentItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" md="6">
                                      <app-input
                                        v-model="
                                          editedDepartmentItem.position_id
                                        "
                                        name="position"
                                        type="select-server"
                                        :label="$t('fields.position')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'audit/master-position/?active=true&state=approved'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <app-input
                                        name="manDay"
                                        rules="required"
                                        :label="$t('fields.manDay')"
                                        :view="view"
                                        :handleBlur="
                                          () => onTimeHrsBlur('manDay')
                                        "
                                        :handleKeyDown="onTimeHrsKeyDow"
                                        v-model="editedDepartmentItem.manDay"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="10">
                                      <app-input
                                        name="note"
                                        rules="required"
                                        type="textarea"
                                        :label="$t('fields.note')"
                                        :view="view"
                                        v-model="editedDepartmentItem.note"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeDepartment"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveDepartment"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '../../../../components/AppTable.vue'

export default {
  name: 'task-template-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      riskDesc: null,
      formData: {
        state: 'draft'
      },
      tab: null,
      approveItems: [
        'approve_name',
        'approve_template_sub_category',
        'approve_master_task_template_header'
      ],
      requiredDocItems: [],
      requiredDocServerItemsLength: 0,
      dialogRequiredDoc: false,
      editedDocumentItem: {},
      questionnaireHeaderItems: [],
      questionnaireHeaderServerItemsLength: 0,
      dialogQuestionnaireHeader: false,
      editedQuestionnaireHeaderItem: [],
      questionnaireDetailItems: [],
      questionnaireDetailServerItemsLength: 0,
      questionnaireLineItems: [],
      questionnaireLineServerItemsLength: 0,
      questionnaireDetailId: null,
      departmentItems: [],
      dialogQuestionnaireLine: false,
      departmentServerItemsLength: 0,
      dialogDepartment: false,
      editedDepartmentItem: [],
      propertyAndAgreementItems: [],
      propertyAndAgreementServerItemsLength: 0,
      dialogPropertyAndAgreement: false,
      editedPropertyAndAgreementItem: [],
      selected: [],
      loading: false
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    requiredDocHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'document_template_id.name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    objectiveHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'objective_id.name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskProfileHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'master_risk_profile_id.name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskDescription() {
      return this.riskDesc
    },
    questionnaireHeader() {
      return [
        { text: this.$t('fields.name'), value: 'master_questionnaire_id.name' },
        {
          text: this.$t('fields.questionType'),
          value: 'questionnaire_type_id.name'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    questionnaireDetailHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.question'),
          value: 'question',
          hideFilter: true
        },
        {
          text: this.$t('fields.answerType'),
          value: 'question_type',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    questionnaireLineHeader() {
      return [
        {
          text: this.$t('fields.choice'),
          value: 'choice',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.points'),
          value: 'points',
          groupable: false,
          sortable: false,
          hideFilter: true
        }
      ]
    },
    positionHeaders() {
      return [
        {
          text: this.$t('fields.positionName'),
          value: 'position_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.manDay'),
          value: 'man_day_converted',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    propertyAndAgreementHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'property_and_agreement',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'taskTemplateCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'taskTemplateEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'taskTemplateEdit',
          text: value ?? 'N/A',
          to: {
            name: 'taskTemplateEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      data.task_sub_category_id = data.task_sub_category_id.id
      if (
        data.master_task_template_header_id !== null &&
        data.master_task_template_header_id !== undefined
      ) {
        data.master_task_template_header_id =
          data.master_task_template_header_id.id
      }
      return data
    },
    getRequiredDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task-template-required-doc?task_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.requiredDocServerItemsLength = data.count
          this.requiredDocItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    onDeleteRequiredDocItem(item) {
      this.onDeleteRequiredDoc(item)
    },
    onDeleteRequiredDoc(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-template-required-doc/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRequiredDocItems()
        })
      })
      this.loading = false
    },
    closeRequiredDoc() {
      this.dialogRequiredDoc = false
      this.$nextTick(() => {
        this.editedDocumentItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveRequiredDoc() {
      if (
        this.editedDocumentItem.document_template_id !== null &&
        this.editedDocumentItem.document_template_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.task_template_header_id = this.$route.params.id
        data.document_template_id =
          this.editedDocumentItem.document_template_id.id

        await this.$api({
          method: 'post',
          url: `task/task-template-required-doc/`,
          data
        })
        this.editedDocumentItem = {}
        this.closeRequiredDoc()
        this.getRequiredDocItems()
      }
    },
    getQuestionnaireHeaderItems() {
      this.loading = true

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task-template-questionnaire-header?task_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.questionnaireHeaderServerItemsLength = data.count
          this.questionnaireHeaderItems = data.results
          if (data.count > 0) {
            this.$api({
              method: 'get',
              url: `task/task-template-questionnaire-detail?task_template_questionnaire_header_id_id=${data.results[0].id}`,
              hideSuccessAlert: true
            }).then(({ data }) => {
              this.questionnaireDetailServerItemsLength = data.count
              this.questionnaireDetailItems = data.results
            })
          } else {
            this.questionnaireDetailServerItemsLength = 0
            this.questionnaireDetailItems = []
          }
        })
      }
      this.loading = false
    },
    onDeleteQuestionnaireHeaderItem(item) {
      this.onDeleteQuestionnaireHeader(item)
    },
    onDeleteQuestionnaireHeader(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-template-questionnaire-header/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getQuestionnaireHeaderItems()
        })
      })
      this.loading = false
    },
    closeQuestionnaireHeader() {
      this.dialogQuestionnaireHeader = false
      this.$nextTick(() => {
        this.editedQuestionnaireHeaderItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveQuestionnaireHeader() {
      if (
        this.editedQuestionnaireHeaderItem.questionnaire_header_id !== null &&
        this.editedQuestionnaireHeaderItem.questionnaire_header_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.task_template_header_id = this.$route.params.id
        data.master_questionnaire_id =
          this.editedQuestionnaireHeaderItem.questionnaire_header_id.master_questionnaire_header_id.id
        data.questionnaire_type_id =
          this.editedQuestionnaireHeaderItem.questionnaire_header_id.questionnaire_type_id.id

        await this.$api({
          method: 'post',
          url: `task/task-template-questionnaire-header/`,
          data
        })
        this.editedQuestionnaireHeaderItem = {}
        this.closeQuestionnaireHeader()
        this.getQuestionnaireHeaderItems()
      }
    },
    onViewQuestionLine(item) {
      this.questionnaireDetailId = item.id
      this.getQuestionnaireLine()
      this.dialogQuestionnaireLine = true
    },
    getQuestionnaireLine() {
      if (
        this.questionnaireDetailId !== null &&
        this.questionnaireDetailId !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task-template-questionnaire-line/?task_template_questionnaire_detail_id_id=${this.questionnaireDetailId}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.questionnaireLineServerItemsLength = data.count
          this.questionnaireLineItems = data.results
        })
      }
      this.loading = false
    },
    closeQuestionnaireLine() {
      this.questionnaireLineItems = []
      this.questionnaireLineServerItemsLength = 0
      this.dialogQuestionnaireLine = false
    },
    getDepartmentItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task-template-position?task_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.departmentServerItemsLength = data.count

          this.departmentItems = data.results.map((item) => ({
            ...item,
            man_day_converted: this.timeHrsAutoConvert(item.manDay)
          }))
        })
      }
      this.loading = false
    },
    onDeleteDepartmentItem(item) {
      this.onDeleteDepartment(item)
    },
    onDeleteDepartment(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-template-position/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getDepartmentItems()
        })
      })
      this.loading = false
    },
    closeDepartment() {
      this.dialogDepartment = false
      this.$nextTick(() => {
        this.editedDepartmentItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveDepartment() {
      if (
        this.editedDepartmentItem.position_id !== null &&
        this.editedDepartmentItem.position_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.task_template_header_id = this.$route.params.id
        data.position_id = this.editedDepartmentItem.position_id.id
        data.manDay = this.timeHrsAutoConvert(
          this.editedDepartmentItem.manDay,
          true
        )
        data.note = this.editedDepartmentItem.note

        await this.$api({
          method: this.editedDepartmentItem.id ? 'put' : 'post',
          url: this.editedDepartmentItem.id
            ? `task/task-template-position/${this.editedDepartmentItem.id}/`
            : `task/task-template-position/`,
          data
        })
        this.editedDepartmentItem = {}
        this.closeDepartment()
        this.getDepartmentItems()
      }
    },
    onEditDepartmentItem(item) {
      this.dialogDepartment = true
      this.editedDepartmentItem = Object.assign({}, item)
      this.editedDepartmentItem.manDay = this.timeHrsAutoConvert(
        this.editedDepartmentItem.manDay
      )
    },
    getPropertyAndAgreementItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task-template-property-and-agreement?task_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.propertyAndAgreementServerItemsLength = data.count
          this.propertyAndAgreementItems = data.results
        })
      }
      this.loading = false
    },
    onDeletePropertyAndAgreementItem(item) {
      this.onDeletePropertyAndAgreement(item)
    },
    onDeletePropertyAndAgreement(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-template-property-and-agreement/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getPropertyAndAgreementItems()
        })
      })
      this.loading = false
    },
    closePropertyAndAgreement() {
      this.dialogPropertyAndAgreement = false
      this.$nextTick(() => {
        this.editedPropertyAndAgreementItem = Object.assign(
          {},
          this.defaultItem
        )
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async savePropertyAndAgreement() {
      if (
        this.editedPropertyAndAgreementItem.property_and_agreement !== null &&
        this.editedPropertyAndAgreementItem.property_and_agreement !== undefined
      ) {
        this.loading = true
        const data = {}
        data.task_template_header_id = this.$route.params.id
        data.property_and_agreement =
          this.editedPropertyAndAgreementItem.property_and_agreement

        await this.$api({
          method: 'post',
          url: `task/task-template-property-and-agreement/`,
          data
        })
        this.editedPropertyAndAgreementItem = {}
        this.closePropertyAndAgreement()
        this.getPropertyAndAgreementItems()
      }
    },
    getDataFromMaster() {
      this.params = {
        id: this.formData.master_task_template_header_id
      }

      if (
        this.formData.master_task_template_header_id !== null &&
        this.formData.master_task_template_header_id !== undefined
      ) {
        this.$api({
          method: 'get',
          url:
            'task/master-task-template-header/?active=true&id=' +
            this.formData.master_task_template_header_id.id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$nextTick(() => {
            this.formData.task_sub_category_id =
              data.results[0].task_sub_category_id
            this.formData.name = data.results[0].name
            this.formData.description = data.results[0].description
            this.formData.active = data.results[0].active
            this.formData.is_audit = data.results[0].is_audit
            this.formData.is_post_audit = data.results[0].is_post_audit
            this.formData.is_pre_audit = data.results[0].is_pre_audit
            this.formData = Object.assign({}, this.formData)
            this.$refs.testSubCategory.setDefaultServerItems(
              this.formData.task_sub_category_id
            )
          })
        })
      }
    },
    onTimeHrsKeyDow(event) {
      const specialKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'ArrowLeft',
        'ArrowRight'
      ]

      if (specialKeys.includes(event.key)) {
        return
      }

      const isDigit = event.key >= '0' && event.key <= '9'
      const isColon =
        event.key === ':' && event.target.value.indexOf(':') === -1

      if (!isDigit && !isColon) {
        event.preventDefault()
      }
    },
    onTimeHrsBlur(field) {
      this.editedDepartmentItem[field] = this.timeHrsAutoConvert(
        this.editedDepartmentItem[field]
      )
    },
    timeHrsAutoConvert(value, return_int = false) {
      let _value = value

      if (_value || _value == 0) {
        _value = value.toString()
        if (_value.includes(':')) {
          const [hours, minutes] = _value.split(':').map(Number)
          _value = `${hours.toString().padStart(2, '0')}:${Math.min(minutes, 59)
            .toString()
            .padStart(2, '0')}`
        } else {
          const totalMinutes = parseInt(_value, 10)
          const hours = Math.floor(totalMinutes / 60)
            .toString()
            .padStart(2, '0')
          const minutes = (totalMinutes % 60).toString().padStart(2, '0')
          _value = `${hours}:${minutes}`
        }

        if (return_int) {
          if (_value.indexOf(':') > -1) {
            const [hours, minutes] = _value.split(':').map(Number)
            _value = hours * 60 + minutes
          } else {
            _value = 0
          }
        }
      }

      return _value
    }
  }
}
</script>
